/* eslint-disable react/no-unused-prop-types */
import { TreemapData } from '.';

export type TreemapRectangleProps<A extends Record<string, number>> = {
  colorMap: Record<string, string>;
  dataKey: keyof A;
  valueFormatter: (value: number) => string;
  totalValue: number;
};

type AllTreeMapRectangleProps<A extends Record<string, number>> =
  TreemapRectangleProps<A> &
    TreemapData<A>[number] & {
      x: number;
      y: number;
      width: number;
      height: number;
      index: number;
    };

const getPercentage = (value: number, totalValue: number): string => {
  const pct = ((value / totalValue) * 100).toFixed(0);
  return `${pct}%`;
};

export function TreemapRectangle<A extends Record<string, number>>(
  props: TreemapRectangleProps<A>,
) {
  const allProps = props as AllTreeMapRectangleProps<A>;

  {
    const {
      dataKey,
      x,
      y,
      width,
      height,
      colorMap,
      valueFormatter,
      name,
      totalValue,
    } = allProps;

    if (!name) {
      return null;
    }

    const value: number = allProps[dataKey];

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: colorMap[name],
            stroke: '#fff',
            strokeWidth: 2,
            strokeOpacity: 1,
          }}
        />
        <text
          x={x + width / 2}
          y={y + height / 2 + 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={14}>
          <tspan x={x + width / 2}>{name}</tspan>
          <tspan x={x + width / 2} dy={18}>
            {valueFormatter(value)}
          </tspan>
          <tspan x={x + width / 2} dy={18}>
            {getPercentage(value, totalValue)}
          </tspan>
        </text>
      </g>
    );
  }
}
