import { Menu } from '@chakra-ui/menu';
import React from 'react';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useMenuFontActiveColor } from 'src/hooks/colors/useMenuFontActiveColor';
import { useAvailableTags } from 'src/hooks/useAvailableTags';
import { useSearch } from 'src/hooks/useSearch';
import { MenuList } from '../../molecules/MenuList';
import { MenuButton } from 'src/components/atoms/MenuButton';

const MENU_BUTTON_NAME = 'tag-select';

export const TagMenu: React.FC = () => {
  const { toggleTags } = useFilterContext();

  const { fontActiveColor } = useMenuFontActiveColor();

  const { selectedTags, uniqueTags } = useAvailableTags();

  const {
    results: searchedTags,
    searchValue,
    setSearchValue,
  } = useSearch(uniqueTags, ['name']);

  const onAllClick = () => {
    toggleTags(searchedTags);
  };

  return (
    <Menu isLazy>
      <MenuButton
        buttonName={MENU_BUTTON_NAME}
        pl="12px"
        fontSize="16px"
        color={fontActiveColor}>
        {selectedTags.length}/{uniqueTags.length}
      </MenuButton>
      <MenuList
        buttonName={MENU_BUTTON_NAME}
        searchProps={{
          onSearchChange: setSearchValue,
          searchValue,
        }}
        onAllClick={onAllClick}>
        {searchedTags.map((tag) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            key={tag.name}
            isSelected={tag.selected}
            value={tag.name}
            label={tag.name}
            onClick={() => toggleTags([tag])}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
