import * as Sentry from '@sentry/react';
import { Context } from '@sentry/types';
import { AuthUser } from 'aws-amplify/auth';
import {
  API_ENDPOINT,
  IS_DEV,
  SENTRY_ENABLED,
  SENTRY_ENV,
} from 'src/constants';
import { getUserType } from 'src/utils/getUserType';
import { dayjs } from './dayjs';

const API_URL_REGEX = new RegExp(`^${API_ENDPOINT}`);

Sentry.init({
  enabled: SENTRY_ENABLED,
  dsn: 'https://e9f84a9fa32b056908fd2a7d5e40b174@o4507503222521856.ingest.de.sentry.io/4507922136957008',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', API_URL_REGEX],
    }),
    new Sentry.Replay(),
  ],
  environment: SENTRY_ENV,
  // Performance Monitoring
  tracesSampleRate: SENTRY_ENV === 'main' ? 1.0 : 0.2, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const setSentryUser = (user: AuthUser | null) => {
  if (user) {
    Sentry.setUser({
      username: user.username,
      id: user.username,
      userType: getUserType(user),
      cognitoId: user.userId,
    });
  } else {
    Sentry.setUser(null);
  }
};

type DebugInfo = {
  fn: string;
  variables?: Context | undefined;
};

// Errors currently can be of type Error | NativeModuleError | AppleError
type CaptureExceptionProp = DebugInfo & {
  error: unknown;
};

export const captureException = ({
  error,
  fn,
  variables,
}: CaptureExceptionProp) => {
  if (IS_DEV) {
    console.log(`error in fn: ${fn}`, error);
  }
  Sentry.captureException(error, {
    contexts: {
      debug: { fn, variables, clientTimestamp: dayjs().toISOString() },
    },
  });
};

type CaptureMessageParams = DebugInfo & {
  message: string;
};

export const captureMessage = ({
  message,
  fn,
  variables,
}: CaptureMessageParams) => {
  if (IS_DEV) {
    console.log(
      `Message: ${message}. Fn: ${fn} Variables: ${JSON.stringify(variables)}`,
    );
  }

  Sentry.captureMessage(message, {
    contexts: {
      debug: {
        fn,
        variables,
        clientTimestamp: dayjs().toISOString(),
      },
    },
  });
};
