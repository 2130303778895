import React from 'react';
import { IconProps, Icon } from '@chakra-ui/icon';

export const Logo: React.FC<IconProps> = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 31.125C26.5919 31.125 31.125 26.5919 31.125 21C31.125 15.4081 26.5919 10.875 21 10.875C15.4081 10.875 10.875 15.4081 10.875 21C10.875 26.5919 15.4081 31.125 21 31.125ZM21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
        fill="currentColor"
      />
    </Icon>
  );
};
