import { useEffect, useRef, useState } from 'react';
import { ApiCampaignDataPoint, useCampaignsQuery } from 'src/api/queries';
import {
  Campaign,
  CampaignTagGroup,
  CampaignToTags,
  TimeRange,
} from 'src/types';
import { AppServices } from 'src/app/routes/utils';
import { toApiDates } from 'src/utils/date';
import { sortByName } from 'src/utils/sort';
import { ModelVersion } from './useModelVersions';

export type CampaignsState = {
  campaigns: Campaign[];
  campaignToTags: CampaignToTags;
  campaignsMap: Record<string, Campaign>;
  isLoading: boolean;
  isError: boolean;
};

const INIT_CAMPAIGNS_STATE: Readonly<CampaignsState> = {
  campaigns: [],
  campaignToTags: {},
  campaignsMap: {},
  isLoading: false,
  isError: false,
};

export const useCampaigns = (
  currentModelVersion: ModelVersion | null,
  timeRange: TimeRange,
  currentAppService: AppServices | null,
  campaignTagGroups: CampaignTagGroup[],
) => {
  const [filterLocked, setFilterLocked] = useState(false);

  const [filteredCampaignIds, setFilteredCampaignIds] = useState<string[]>([]);

  const lockedCampaignsRef = useRef<Array<ApiCampaignDataPoint>>([]);

  const [campaignsState, setCampaignsState] =
    useState<CampaignsState>(INIT_CAMPAIGNS_STATE);

  const setCampaigns = (campaigns: Array<Campaign>) => {
    setCampaignsState((curr) => {
      return {
        ...curr,
        campaigns,
      };
    });
  };

  const enabled = !!currentModelVersion && currentAppService !== AppServices.LT;

  // Fetch campaigns data for filters
  const {
    data: campaignsData,
    isLoading: campaignsLoading,
    isError: campaignsError,
  } = useCampaignsQuery(
    {
      customer: currentModelVersion?.customerName ?? 'missing_customer',
      model_build: currentModelVersion?.buildID ?? 'missing_build',
      ...toApiDates(timeRange),
    },
    enabled,
  );

  // Update the list of campaigns when new data is fetched.
  useEffect(() => {
    if (!enabled) {
      setCampaignsState(INIT_CAMPAIGNS_STATE);
      return;
    }

    const lockedIdsRecordSet = new Set(
      lockedCampaignsRef.current.map((c) => c.id),
    );

    const fetchedData = campaignsData ?? [];

    const newIdSet = new Set<string>();

    const formattedCampaigns: Campaign[] = fetchedData.map((c) => {
      const selected = !filterLocked || lockedIdsRecordSet.has(c.id);

      newIdSet.add(c.id);

      return {
        ...c,
        selected,
        visible: true,
        isAvailable: true,
      };
    });

    if (filterLocked) {
      lockedCampaignsRef.current.forEach((c) => {
        if (!newIdSet.has(c.id)) {
          formattedCampaigns.push({
            ...c,
            isAvailable: false,
            selected: false,
            visible: true,
          });
        }
      });
    }

    // Set new campaign to tags
    const newCampaignToTags: CampaignToTags = {};
    formattedCampaigns.forEach((campaign) => {
      newCampaignToTags[campaign.id] = {
        allTags: campaign.tags,
        category:
          campaign.tags.find((t) => t.tagGroup === 'tag_2')?.tag ?? null,
      };
    });

    // Set new campaigns map
    const newCampaignsMap: Record<string, Campaign> = formattedCampaigns.reduce(
      (prev: Record<string, Campaign>, curr: Campaign) => {
        // eslint-disable-next-line no-param-reassign
        prev[curr.id] = curr;
        return prev;
      },
      {},
    );

    setCampaignsState({
      campaigns: sortByName(formattedCampaigns),
      campaignToTags: newCampaignToTags,
      campaignsMap: newCampaignsMap,
      isLoading: campaignsLoading,
      isError: campaignsError,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignsData, campaignsLoading, campaignsError, enabled]);

  useEffect(() => {
    // Whenever the campaign tag groups are changed, set the corresponding
    // visibility for the campaigns.
    const updatedCampaigns = campaignsState.campaigns.map((campaign) => {
      const isCampaignVisible = (): boolean => {
        const campaignTags = campaign.tags;

        // Campaigns without any tags are always visible
        // Also campaigns that are not available, should also always be displayed
        if (campaignTags.length === 0 || !campaign.isAvailable) {
          return true;
        }

        const isVisisble = campaignTags.reduce((prev, campaignTag) => {
          const tagSelected = campaignTagGroups.reduce((p, group) => {
            const match =
              campaignTag.tagGroup === group.tagGroup &&
              group.selected &&
              group.tags.findIndex(
                (t) => t.name === campaignTag.tag && t.selected && t.visible,
              ) > -1;
            return p || match;
          }, false);

          return prev || tagSelected;
        }, false);
        return isVisisble;
      };

      const visible = isCampaignVisible();

      return {
        ...campaign,
        selected: visible ? campaign.selected : false,
        visible,
      };
    });

    setCampaigns(updatedCampaigns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignTagGroups]);

  useEffect(() => {
    // Set filtered campaign ids to make it easier to filter results
    const selectedCampaigns = campaignsState.campaigns.filter(
      (c) => c.selected,
    );

    setFilteredCampaignIds(selectedCampaigns.map((c) => c.id));

    if (!filterLocked) {
      lockedCampaignsRef.current = [];
      return;
    }

    if (campaignsState.campaigns.length === 0) {
      return;
    }

    // We want to keep the previous not available campagins still in the locked campaigns ref
    lockedCampaignsRef.current = campaignsState.campaigns.filter(
      (c) => c.selected || !c.isAvailable,
    );
  }, [campaignsState.campaigns, filterLocked]);

  // Needs another useEffect vs using one above to avoid a recursive setCampaigns
  useEffect(() => {
    if (!filterLocked) {
      const availableCampaigns = campaignsState.campaigns.filter(
        (c) => c.isAvailable,
      );

      setCampaigns(availableCampaigns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterLocked]);

  return {
    campaignsLocked: filterLocked,
    setCampaignsLocked: setFilterLocked,
    campaigns: campaignsState.campaigns,
    setCampaigns,
    filteredCampaignIds,
    campaignsMap: campaignsState.campaignsMap,
    campaignToTags: campaignsState.campaignToTags,
  };
};
