import * as React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { BrandVsOthersChangeInSearchVolumeChartBox } from '../../organisms/BrandVsOthersChangeInSearchVolumeChartBox';
import { BrandVsOthersSearchVolumeChartBox } from '../../organisms/BrandVsOthersSearchVolumeChartBox';
import { SosByMonthsKeywordChartBox } from '../../organisms/SosByMonthsKeywordChartBox';
import { SosByRegionMap } from '../../organisms/SosByRegionMap';
import { getMapConfig } from '../../organisms/SosByRegionMap/supportedCountries';
import { SosByYearsCountryTableBox } from '../../organisms/SosByYearsCountryTableBox';

export const Overview: React.FC = () => {
  const { selectedCountry } = useSOSDataContext();

  const { value: mapConfig } = useAsync(async () => {
    const data = await getMapConfig(selectedCountry.countryCode);

    return data;
  }, [selectedCountry.countryCode]);

  return (
    <>
      <SosByMonthsKeywordChartBox
        minH={400}
        colSpan={12}
        title="Search by months"
      />
      <BrandVsOthersSearchVolumeChartBox
        minH={400}
        colSpan={12}
        title="Search volume of brand vs others"
      />
      <BrandVsOthersChangeInSearchVolumeChartBox
        minH={400}
        colSpan={12}
        title="Change in search volume of brand vs others"
      />
      {mapConfig && (
        <SosByRegionMap
          minH={700}
          maxH={700}
          title="Share of search last 12 months"
          colSpan={10}
          mapConfig={mapConfig}
        />
      )}
      <SosByYearsCountryTableBox
        title="Share of search by years"
        colSpan={12}
      />
    </>
  );
};
