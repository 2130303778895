import { Box, BoxProps, SystemStyleObject } from '@chakra-ui/react';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import ReactDatepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export type DatePickerProps = {
  selectedDate: Date | undefined;
  onChange: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  id?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  sx?: BoxProps['sx'];
};

const selectStyle: SystemStyleObject = {
  color: 'black',
  bgColor: 'white',
  borderRadius: '4px',
  px: '4px',
  py: '2px',
  mt: '10px',
};

export const DatePicker = React.forwardRef<ReactDatepicker, DatePickerProps>(
  ({ selectedDate, onChange, id, onBlur, sx, minDate, maxDate }, ref) => {
    return (
      <Box
        sx={{
          ...sx,
          '.react-datepicker__month-select': selectStyle,
          '.react-datepicker__year-select': selectStyle,
        }}>
        <ReactDatepicker
          disabledKeyboardNavigation
          ref={ref}
          id={id}
          clearButtonTitle="clear"
          isClearable
          inline
          minDate={minDate}
          maxDate={maxDate}
          openToDate={selectedDate ?? undefined}
          selected={selectedDate}
          showMonthDropdown
          showYearDropdown
          onBlur={onBlur}
          dropdownMode="select"
          onChange={(updatedStart: Date) => {
            onChange(updatedStart);
          }}
        />
      </Box>
    );
  },
);

DatePicker.displayName = 'DatePicker';
