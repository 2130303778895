import { Menu } from '@chakra-ui/menu';
import React from 'react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { MenuList } from 'src/components/molecules/MenuList';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { useSearch } from 'src/hooks/useSearch';

const MENU_BUTTON_NAME = 'keyword-select';

export const KeywordMenu: React.FC = () => {
  const { shareOfSearchCountryMonthlyState, toggleKeywords } =
    useSOSDataContext();

  const selectedKeywords =
    shareOfSearchCountryMonthlyState.selectableKeywords.filter(
      (c) => c.selected,
    );

  const {
    results: searchedKeywords,
    searchValue,
    setSearchValue,
  } = useSearch(shareOfSearchCountryMonthlyState.selectableKeywords, [
    'keyword',
  ]);

  const onAllClick = () => {
    toggleKeywords(searchedKeywords);
  };

  return (
    <Menu isLazy>
      <MenuButton buttonName={MENU_BUTTON_NAME} pl="12px" fontSize="16px">
        {selectedKeywords.length}/
        {shareOfSearchCountryMonthlyState.selectableKeywords.length}
      </MenuButton>
      <MenuList
        buttonName={MENU_BUTTON_NAME}
        searchProps={{
          onSearchChange: setSearchValue,
          searchValue,
        }}
        onAllClick={onAllClick}>
        {searchedKeywords.map((d) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            label={d.keyword}
            value={d.keyword}
            key={d.keyword}
            isSelected={d.selected}
            onClick={() => toggleKeywords([d])}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
