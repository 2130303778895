/* eslint-disable prefer-exponentiation-operator */
import { range } from 'lodash';
import { ApiABCResponseCurveDatapoint } from 'src/api/queries';
import { ResponseCurveData, ResponseCurveDatapoint } from 'src/types';

export const getResponseForSpend = (
  spend: number,
  data: Array<ResponseCurveDatapoint>,
): number => {
  if (data.length === 0 || !spend) {
    return 0;
  }
  return data.find((x) => x.spend === spend)?.response || 0;
};

export const getLargestMeanSpend = (data: ApiABCResponseCurveDatapoint[]) => {
  if (data.length === 0) {
    return 0;
  }
  let largestOptimalSpend = data[0].meanSpend;
  data.forEach((d) => {
    if (d.meanSpend > largestOptimalSpend) {
      largestOptimalSpend = d.meanSpend;
    }
  });

  return largestOptimalSpend;
};

const calculateResponse = (
  spend: number,
  abcParameters: ApiABCResponseCurveDatapoint,
) => {
  const { abcA, abcB, abcC } = abcParameters;

  return abcA / (1 + abcB * Math.pow(spend, abcC));
};

export const generateResponseCurveDatapoints = (
  data: ApiABCResponseCurveDatapoint[],
  numberOfDatapoints: number,
  valueMultiplier: number,
  extendSpendMultiplier: number,
): Array<ResponseCurveData> => {
  const largestMeanSpend = getLargestMeanSpend(data);

  const largestSpend = largestMeanSpend * extendSpendMultiplier;

  const step = largestSpend / numberOfDatapoints;
  const spendRange = range(0, largestSpend, step);

  // Ensures that meanSpends also get response values
  data.forEach(({ meanSpend }) => {
    spendRange.push(meanSpend);
  });

  const responseCurveData: Array<ResponseCurveData> = [];

  spendRange.sort((a, b) => a - b);

  for (const abcData of data) {
    const channel = abcData.channel;
    const datapoints: Array<ResponseCurveDatapoint> = [];

    for (const spend of spendRange) {
      const response = calculateResponse(spend, abcData);
      datapoints.push({
        spend: Math.round(spend * valueMultiplier),
        response: Math.round(response * valueMultiplier),
      });
    }
    responseCurveData.push({
      channel,
      data: datapoints,
      meanResponse: Math.round(abcData.meanResponse * valueMultiplier),
      meanSpend: Math.round(abcData.meanSpend * valueMultiplier),
    });
  }

  return responseCurveData;
};
