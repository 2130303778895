import {
  AuthUser,
  signIn as ampSignIn,
  signOut as ampSignOut,
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  getCurrentUser,
  resetPassword,
} from 'aws-amplify/auth';

import { PropsWithChildren, useMemo, useState } from 'react';
import { setAmplitudeUser, unsetAmplitudeUser } from 'src/libs/amp';
import { AuthContext, CurrentAuthState, UserProfile } from '.';
import { getUserProfile } from './utils';
import { setSentryUser } from 'src/libs/sentry';

type AuthError = {
  name: string;
  message?: string;
};

const isAuthError = (error: unknown): error is AuthError => {
  return 'name' in (error as AuthError);
};

export function AuthContextProvider(props: PropsWithChildren) {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  const [currentAuthState, setCurrentAuthState] = useState<CurrentAuthState>(
    CurrentAuthState.Loading,
  );

  const getAndSetUserProfile = async (user: AuthUser) => {
    const { tokens } = await fetchAuthSession();

    if (!tokens) {
      signOut();
      return;
    }

    const currentUserProfile = await getUserProfile(user, tokens);
    setSentryUser(user);
    setAmplitudeUser(user, currentUserProfile?.role ?? null);

    setUserProfile(currentUserProfile);
    setCurrentAuthState(CurrentAuthState.SignedIn);
  };

  /**
   * This function is design to refresh the Context's
   * when needed (usually when the page is reloaded).
   * It will use the Amplify functions to check the current
   * authentication state of the user and synchronise this
   * context with that.
   */
  async function refreshAuthState() {
    try {
      const { tokens } = await fetchAuthSession();
      if (!tokens) {
        setUserProfile(null);
        setCurrentAuthState(CurrentAuthState.SignedOut);
        return;
      }
      const nextCognitoUser = await getCurrentUser();
      await getAndSetUserProfile(nextCognitoUser);
    } catch (error) {
      console.log('Error in refreshAuth, signing out', error);
      signOut();
    }
  }

  async function signIn(username: string, password: string) {
    try {
      const signInResult = await ampSignIn({ username, password });

      if (
        signInResult.nextStep.signInStep ===
        'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        setCurrentAuthState(CurrentAuthState.ForcePasswordChange);
        return;
      }

      const user = await getCurrentUser();
      getAndSetUserProfile(user);
    } catch (error) {
      if (!isAuthError(error)) {
        throw error;
      }

      if (error.name === 'UserNotFoundException') {
        error.message = 'Invalid username or password';
      }
      if (error.name === 'UserAlreadyAuthenticatedException') {
        signOut();
      }

      throw error;
    }
  }

  async function completeNewPassword(newPassword: string) {
    try {
      await confirmSignIn({
        challengeResponse: newPassword,
      });

      const user = await getCurrentUser();
      getAndSetUserProfile(user);
    } catch (error) {
      if (!isAuthError(error)) {
        throw error;
      }
      if (error.name === 'UserNotFoundException') {
        error.message = 'Invalid username or password';
      }
      throw error;
    }
  }

  /**
   * This function is required so that the Forgot/Reset Password
   * flow is entered. This function is Async to keep the API for these
   * functions consistent and to make it easy to add Async congito auth
   * actions in the future.
   */
  async function forgotPasswordStart() {
    setCurrentAuthState(CurrentAuthState.ForgotPassword);
  }

  async function forgotPasswordSendCode(username: string) {
    try {
      await resetPassword({ username });
      setCurrentAuthState(CurrentAuthState.PasswordReset);
      // This promise does not return anything. In order to retain the username
      // we must manually create a temporary  CognitoUser object.
      // This allows for the 'username' to be automatically filled for a user in
      // the UpdatePassword screen.
    } catch (error) {
      if (!isAuthError(error)) {
        throw error;
      }
      if (error.name === 'UserNotFoundException') {
        error.message = 'Invalid username or password';
      }
      throw error;
    }
  }

  async function forgotPasswordUpdatePassword(
    username: string,
    code: string,
    newPassword: string,
  ) {
    try {
      await confirmResetPassword({
        username,
        confirmationCode: code,
        newPassword,
      });
      // Attempt sign in with new password to complete the reset process
      await signIn(username, newPassword);
    } catch (error) {
      if (!isAuthError(error)) {
        throw error;
      }
      if (error.name === 'UserNotFoundException') {
        error.message = 'Invalid username';
      }
      throw error;
    }
  }

  async function signOut() {
    try {
      await ampSignOut();
    } catch (e) {
      console.log('Error in signout', e);
    } finally {
      unsetAmplitudeUser();
      setSentryUser(null);
      setUserProfile(null);
      setCurrentAuthState(CurrentAuthState.SignedOut);
    }
  }

  const value = useMemo(() => {
    return {
      refreshAuthState,
      signIn,
      completeNewPassword,
      forgotPasswordStart,
      forgotPasswordSendCode,
      forgotPasswordUpdatePassword,
      signOut,
      userProfile,
      currentAuthState,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, currentAuthState]);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
