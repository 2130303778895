import { PropsWithChildren, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { SOSDataContext } from '.';
import { useAvailableCountries } from '../../hooks/useAvailableCountries';
import { useSelectableAggregateLocations } from '../../hooks/useSelectableAggregateLocations';
import { useShareOfSearchCities } from '../../hooks/useShareOfSearchCities';
import { useShareOfSearchCountryMonthly } from '../../hooks/useShareOfSearchCountryMonthly';
import { useShareOfSearchLocationsAggregate } from '../../hooks/useShareOfSearchLocationsAggregate';
import { useShareOfSearchByRegionPast12Months } from '../../hooks/useShareOfSearchRegions';
import { useAvailableRegionsAndCitiesForCountry } from '../../hooks/useAvailableRegionsAndCitiesForCountry';

export function SOSDataContextProvider(props: PropsWithChildren) {
  const { subscriptionLevels, currentCustomer } = useAppContext();

  // Make sure we only run queries if customers have the correct subscriptions enabled
  // and a model version is selected (so that there are no errors & data is available)
  const enabled = subscriptionLevels.sos && !!currentCustomer;

  const { availableCountriesState, selectedCountry, setSelectedCountry } =
    useAvailableCountries(enabled);

  const { availableRegionsAndCitiesState, toggleCities, toggleRegions } =
    useAvailableRegionsAndCitiesForCountry(enabled, selectedCountry);

  const { shareOfSearchCountryMonthlyState, toggleKeywords } =
    useShareOfSearchCountryMonthly(enabled, selectedCountry.locationId);

  const {
    shareOfSearchRegionsState,
    regionsBenchmarkDates,
    setRegionsBenchmarkDates,
  } = useShareOfSearchByRegionPast12Months(
    enabled,
    shareOfSearchCountryMonthlyState.selectableKeywords,
    selectedCountry,
    availableRegionsAndCitiesState.regions,
  );

  const {
    shareOfSearchCitiesState,
    setCitiesBenchmarkDates,
    citiesBenchmarkDates,
  } = useShareOfSearchCities(
    enabled,
    shareOfSearchCountryMonthlyState.selectableKeywords,
    selectedCountry,
    availableRegionsAndCitiesState.selectableCities,
  );

  const { selectableAggregateLocations, toggleAggregateLocations } =
    useSelectableAggregateLocations(
      availableRegionsAndCitiesState,
      selectedCountry,
    );

  const { shareOfSearchLocationsAggregateState } =
    useShareOfSearchLocationsAggregate(
      enabled,
      shareOfSearchCountryMonthlyState.selectableKeywords,
      selectableAggregateLocations,
    );

  const value = useMemo(() => {
    const selectableKeywords =
      shareOfSearchCountryMonthlyState.selectableKeywords;

    const selectedKeywords = selectableKeywords
      .filter(({ selected }) => selected)
      .map(({ keyword }) => keyword);

    return {
      shareOfSearchCountryMonthlyState,
      shareOfSearchCitiesState,
      shareOfSearchLocationsAggregateState,
      toggleRegions,
      toggleCities,
      toggleKeywords,
      shareOfSearchRegionsState,
      selectableAggregateLocations,
      availableRegionsAndCitiesState,
      toggleAggregateLocations,
      setSelectedCountry,
      selectedCountry,
      selectedKeywords,
      availableCountriesState,
      citiesBenchmarkDates,
      regionsBenchmarkDates,
      setCitiesBenchmarkDates,
      setRegionsBenchmarkDates,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shareOfSearchRegionsState,
    shareOfSearchCountryMonthlyState,
    shareOfSearchCitiesState,
    shareOfSearchLocationsAggregateState,
    availableRegionsAndCitiesState,
    toggleRegions,
    toggleCities,
    selectableAggregateLocations,
    selectedCountry,
    availableCountriesState,
    citiesBenchmarkDates,
    regionsBenchmarkDates,
  ]);

  return (
    <SOSDataContext.Provider value={value}>
      {props.children}
    </SOSDataContext.Provider>
  );
}
