import { Box } from '@chakra-ui/react';
import { ResponsiveContainer, Tooltip, Treemap } from 'recharts';
import { DataKey } from 'recharts/types/util/types';
import { TooltipContent } from '../../../atoms/tooltips/TooltipContent';
import { TreemapRectangle } from './TreemapRectangle';

export type TreemapData<A extends Record<string, number>> = Array<
  {
    name: string;
  } & A
>;

type TreemapGraphProps<A extends Record<string, number>> = {
  data: TreemapData<A>;
  dataKey: keyof A;
  colorMap: Record<string, string>;
  valueFormatter: (value: number) => string;
};

export function TreemapGraph<A extends Record<string, number>>({
  data,
  dataKey,
  colorMap,
  valueFormatter,
}: TreemapGraphProps<A>) {
  const totalValue = data.reduce((prev, curr) => prev + curr[dataKey], 0);

  return (
    <Box w="100%" h="100%">
      <ResponsiveContainer>
        <Treemap
          isAnimationActive={false}
          data={data}
          dataKey={dataKey as DataKey<A>} // Recharts typing is too generic
          content={
            <TreemapRectangle<A>
              totalValue={totalValue}
              valueFormatter={valueFormatter}
              dataKey={dataKey}
              colorMap={colorMap}
            />
          }>
          <Tooltip
            separator=""
            // eslint-disable-next-line react/no-unstable-nested-components
            content={(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              props: any, // Tooltip works wrong in recharts treemap so need to mess around with ti
            ) => (
              <TooltipContent
                {...props}
                label={props.payload[0]?.payload?.name ?? ''}
                labelFormatter={(x) => x.toString()}
                valueFormatter={valueFormatter}
              />
            )}
          />
        </Treemap>
      </ResponsiveContainer>
    </Box>
  );
}
