import { Flex } from '@chakra-ui/layout';
import React from 'react';
import { FilterHeading } from 'src/components/atoms/FilterHeading';
import { FilterBarContainer } from 'src/components/molecules/FilterBarContainer';
import { MediaMenu } from 'src/components/organisms/menus/MediaMenu';
import { ModelVersionSelect } from 'src/components/molecules/ModelVersionSelect';
import { TimeRangeSelector } from 'src/components/organisms/TimeRangeSelector';

export const LTFilterBar: React.FC = () => {
  return (
    <FilterBarContainer>
      <Flex w="100%" alignItems="center">
        <Flex direction="row" alignItems="flex-end">
          <FilterHeading headingText="Media" />
          <MediaMenu />
        </Flex>
        <Flex ml="30px" direction="row" alignItems="center">
          <FilterHeading mr="12px" headingText="Time range" />
          <TimeRangeSelector />
        </Flex>
        <Flex
          flexGrow={1}
          direction="column"
          alignItems="flex-end"
          justifyContent="flex-end">
          <ModelVersionSelect />
        </Flex>
      </Flex>
    </FilterBarContainer>
  );
};
