import { Menu } from '@chakra-ui/menu';
import React, { useMemo } from 'react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { MenuList } from 'src/components/molecules/MenuList';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { SosLocation } from 'src/features/SOS/types';
import { useMenuFontActiveColor } from 'src/hooks/colors/useMenuFontActiveColor';
import { useSearch } from 'src/hooks/useSearch';

const MENU_BUTTON_NAME = 'selected-location-select';

const getMenuLabel = (
  selectedLocations: Array<SosLocation>,
  selectableLocations: Array<SosLocation>,
) => {
  if (selectedLocations.length === 1) {
    return selectedLocations[0].locationName;
  } else {
    return `${selectedLocations.length}/${selectableLocations.length}`;
  }
};

export const SelectedLocationsMenu: React.FC = () => {
  const { selectableAggregateLocations, toggleAggregateLocations } =
    useSOSDataContext();

  const { fontActiveColor } = useMenuFontActiveColor();

  const selectedLocations = selectableAggregateLocations.filter(
    ({ selected }) => selected,
  );

  const selectedLocationsSet = useMemo(() => {
    return new Set(selectedLocations.map((l) => l.locationId));
  }, [selectedLocations]);

  const {
    results: searchedLocations,
    searchValue,
    setSearchValue,
  } = useSearch(selectableAggregateLocations, ['locationName']);

  const onAllClick = () => {
    toggleAggregateLocations(searchedLocations);
  };

  return (
    <Menu isLazy>
      <MenuButton
        buttonName={MENU_BUTTON_NAME}
        pl="12px"
        fontSize="16px"
        color={fontActiveColor}>
        {getMenuLabel(selectedLocations, selectableAggregateLocations)}
      </MenuButton>
      <MenuList
        searchProps={{
          onSearchChange: setSearchValue,
          searchValue,
        }}
        onAllClick={onAllClick}
        buttonName={MENU_BUTTON_NAME}>
        {searchedLocations.map((location) => {
          return (
            <MenuOption
              buttonName={MENU_BUTTON_NAME}
              label={location.locationName}
              value={location.locationId}
              key={location.locationId}
              isSelected={selectedLocationsSet.has(location.locationId)}
              onClick={() => toggleAggregateLocations([location])}
            />
          );
        })}
      </MenuList>
    </Menu>
  );
};
