import { Menu } from '@chakra-ui/menu';
import React from 'react';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { MenuList } from 'src/components/molecules/MenuList';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';

const MENU_BUTTON_NAME = 'country-select';

export const CountryMenu: React.FC = () => {
  const { availableCountriesState, setSelectedCountry, selectedCountry } =
    useSOSDataContext();

  return (
    <Menu isLazy>
      <MenuButton buttonName={MENU_BUTTON_NAME} pl="12px" fontSize="16px">
        {selectedCountry.locationName}
      </MenuButton>
      <MenuList buttonName={MENU_BUTTON_NAME}>
        {availableCountriesState.data.map((d) => (
          <MenuOption
            buttonName={MENU_BUTTON_NAME}
            key={d.locationId}
            label={d.locationName}
            value={d.locationId}
            isSelected={d.locationId === selectedCountry.locationId}
            onClick={() => setSelectedCountry(d)}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
