const geoJsons = import.meta.glob('./regionData/*.json');

import { GeoJsonType } from './types';

// France: https://github.com/gregoiredavid/france-geojson/blob/master/regions.geojson
// Finland: Sadly do not remember what the original source was for this geojson
// Belgium: Edited manually from https://github.com/mathiasleroy/Belgium-Geographic-Data <- to get regions
// Sweden:  https://github.com/okfse/sweden-geojson/blob/master/swedish_regions.geojson
// Italy: https://github.com/openpolis/geojson-italy/blob/master/geojson/limits_IT_regions.geojson
// Spain: https://github.com/codeforgermany/click_that_hood/blob/main/public/data/spain-communities.geojson
// Poland: https://github.com/jusuff/PolandGeoJson/blob/main/data/poland.voivodeships.json
// Germany: https://github.com/isellsoap/deutschlandGeoJSON/blob/main/2_bundeslaender/3_mittel.geo.json
// UK https://geoportal.statistics.gov.uk/datasets/99ac6ae4ea4d42a9b0fff4171b7366db_0/explore?location=54.954527%2C0.026752%2C6.62 <- good service in general, search with countries and boundaries. However, gives in BNG format and need to change to normal lat long
// Czech https://github.com/siwekm/czech-geojson <- Large file so downsampled with this repos tasks/files/downsampleMapData function
// Turkey: https://github.com/alpers/Turkey-Maps-GeoJSON/blob/master/tr-cities.json

type MapInitState = {
  longitude: number;
  latitude: number;
  zoom: number;
  pitch: number;
};

const INIT_FINLAND_STATE: MapInitState = {
  longitude: 24.75,
  latitude: 65.5,
  zoom: 4,
  pitch: 0,
};

const INIT_BELIGUM_STATE: MapInitState = {
  longitude: 4.35,
  latitude: 50.5,
  zoom: 7,
  pitch: 0,
};

const INIT_SWEDEN_STATE: MapInitState = {
  longitude: 14.75,
  latitude: 63,
  zoom: 3.8,
  pitch: 0,
};

const INIT_FRANCE_STATE: MapInitState = {
  longitude: 4.152568,
  latitude: 46.742174,
  zoom: 4.8,
  pitch: 0,
};

const INIT_ITALY_STATE: MapInitState = {
  longitude: 13.018973,
  latitude: 42.395511,
  zoom: 4.8,
  pitch: 0,
};

const INIT_SPAIN_STATE: MapInitState = {
  longitude: -2.921104,
  latitude: 40.170786,
  zoom: 4.8,
  pitch: 0,
};

const INIT_POLAND_STATE: MapInitState = {
  longitude: 19.600881,
  latitude: 52.084867,
  zoom: 5.2,
  pitch: 0,
};

const INIT_GERMANY_STATE: MapInitState = {
  longitude: 10.671087,
  latitude: 51.280375,
  zoom: 5.0,
  pitch: 0,
};

const INIT_CZECHIA_STATE: MapInitState = {
  longitude: 15.476329,
  latitude: 49.841756,
  zoom: 6.1,
  pitch: 0,
};

const INIT_UK_STATE: MapInitState = {
  longitude: -3.753572,
  latitude: 55.870916,
  zoom: 4.4,
  pitch: 0,
};

const INIT_TURKEY_STATE: MapInitState = {
  longitude: 35.360335,
  latitude: 39.326147,
  zoom: 5.1,
  pitch: 0,
};

export type MapConfig = {
  mapState: MapInitState;
  geoJson: GeoJsonType;
};

const getGeoJson = async (fileName: string) => {
  const getter = geoJsons[`./regionData/${fileName}.json`];

  const module = await getter();

  // Using this dynamic import, the json will be in a default object
  return (module as { default: GeoJsonType }).default;
};

export const SUPPORTED_COUNTRIES: Readonly<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, { mapState: MapInitState; geoJsonName: string }>
> = {
  FI: {
    mapState: INIT_FINLAND_STATE,
    geoJsonName: 'finland',
  },
  BE: {
    mapState: INIT_BELIGUM_STATE,
    geoJsonName: 'belgium',
  },
  SE: {
    mapState: INIT_SWEDEN_STATE,
    geoJsonName: 'sweden',
  },
  FR: {
    mapState: INIT_FRANCE_STATE,
    geoJsonName: 'france',
  },
  IT: {
    mapState: INIT_ITALY_STATE,
    geoJsonName: 'italy',
  },
  ES: {
    mapState: INIT_SPAIN_STATE,
    geoJsonName: 'spain',
  },
  PL: {
    mapState: INIT_POLAND_STATE,
    geoJsonName: 'poland',
  },
  DE: {
    mapState: INIT_GERMANY_STATE,
    geoJsonName: 'germany',
  },
  CZ: {
    mapState: INIT_CZECHIA_STATE,
    geoJsonName: 'czechia',
  },
  GB: {
    mapState: INIT_UK_STATE,
    geoJsonName: 'uk',
  },
  TR: {
    mapState: INIT_TURKEY_STATE,
    geoJsonName: 'turkey',
  },
};

export const getMapConfig = async (
  countryCode: string,
): Promise<MapConfig | null> => {
  const mapConfig = SUPPORTED_COUNTRIES[countryCode];

  if (!mapConfig) {
    return null;
  }

  const geoJson = await getGeoJson(mapConfig.geoJsonName);

  return {
    mapState: mapConfig.mapState,
    geoJson,
  };
};
